import { AuthenticationResult } from '@azure/msal-browser';
import React, { createContext, useEffect, useState } from 'react';

export const AuthContext = createContext<{
    token: AuthenticationResult | null;
    setToken: React.Dispatch<React.SetStateAction<AuthenticationResult | null>>;
    accessLevel: 1 | 2 | 3 | null;
}>({} as any);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [token, setToken] = useState<AuthenticationResult | null>(null);
    const [accessLevel, setAccessLevel] = useState<1 | 2 | 3 | null>(null);

    const getLevelAccess = (groupHash: string[]) => {
        const permissions = {
            1: [process.env.REACT_APP_ADM_HASH as string, process.env.REACT_APP_QA_HASH as string],
            2: process.env.REACT_APP_VIEWER_HASH as string,
        };

        if (groupHash.includes(permissions[1][0]) || groupHash.includes(permissions[1][1])) {
            setAccessLevel(1);
            return;
        }

        if (groupHash.includes(permissions[2])) {
            setAccessLevel(2);
            return;
        }
    };

    const logout = () => {
        // remove token
        // to login
        setToken(null);
        localStorage.removeItem('token');
        window.location.href = '/login';
    };

    useEffect(() => {
        const recoveredToken = localStorage.getItem('token');
        if (!token && recoveredToken) {
            const parsedToken = JSON.parse(recoveredToken);
            setToken(parsedToken);

            if (!accessLevel) {
                if (parsedToken.idTokenClaims.groups)
                    getLevelAccess(parsedToken.idTokenClaims.groups);
                else logout();
            }
        } else if (token && !accessLevel) {
            if ((token.idTokenClaims as any).groups)
                getLevelAccess((token.idTokenClaims as any).groups);
            else logout();
        }
    }, [token]);

    return (
        <AuthContext.Provider value={{ token, setToken, accessLevel }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
